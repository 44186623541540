svg {
  width: 1em;
  height: 1em;
}

.highlighter-rouge {
  @extend .mb-3;
}


.random-bg-hover {
  > span {
    width: 25%;
    transition: width .25s ease-out, opacity .25s ease-out;
    opacity: 0;
    transform-origin: center;
    transform: translateX(-50%);
  }
  &.active,
  &:hover, 
  &:focus {
    > span {
      opacity: 0.3;
      width: 200%;
    }
  }
  &:active {
    > span {
      opacity: 0.5;
      width: 200%;
    }
  }
}